import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// Components
import Home from './container/Home';
import About from './container/About';

// Theme and styles
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import variables from './css/App.scss';

const Users = () => <h2>Users</h2>;

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: '#484848',
      // dark: '#000000',
      // contrastText: '#000000',
      main: variables.primaryColor
    },
    secondary: {
      // light: '#ad50d9',
      // dark: '#480077',
      // contrastText: '#000000',
      main: variables.secondaryColor
    }
  },
  typography: {
    useNextVariants: true
  }
});

class App extends Component {
  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <header className="header" />
          <div className="container">
            <Route path="/" exact component={Home} />
            <Route path="/about/" component={About} />
            <Route path="/users/" component={Users} />
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
