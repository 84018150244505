import React, { Component } from 'react';
import Album from '../presentational/Album';

export class About extends Component {
  render() {
    return <Album />;
  }
}

export default About;
