import React, { Component } from 'react';

export class home extends Component {
  render() {
    return (
      <div className="home">
        <a className="logo-symbol-text primary-color" href="/">
          [¿,]
        </a>
        <br />
        <a className="logo-text primary-color" href="/">
          lateralley
        </a>
      </div>
    );
  }
}

export default home;
